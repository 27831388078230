<div class="page-body desktop-panel" [ngClass]="isDesktop ? 'desktop' : 'mobile'">
  <div  *ngIf="pendingList?.results?.length > 0; else nothing">
    <div *ngIf="pendingList?.results?.length > 0 && isDesktop" class="pw-header-content">
      <div>
        <app-common-page-title icon="assets/icons/icon-pending-withdrawals.svg"> <ng-container i18n>Pending Withdrawals</ng-container> </app-common-page-title>
      </div>
      <div class="pending-withdrawals-icon" fxLayout="row" fxLayoutAlign="center center">
        <div class="count">{{pendingList.count}}</div>
      </div>
    </div>
    <div *ngIf="pendingList?.results?.length > 0 && !isDesktop" class="pw-header-content">
      <div>
        <app-mobile-page-back-header iconName="icon-pending-withdrawals.svg">Pending Withdrawals</app-mobile-page-back-header>
      </div>
      <div class="pending-withdrawals-icon" fxLayout="row" fxLayoutAlign="center center">
        <div class="count">{{pendingList.count}}</div>
      </div>
    </div>
    <div class="results">
      <div class="pending-info-msg">
        <img src="assets/icons/icon_info.svg" alt="icon-info">
        <p i18n>Pending time might take up from 3 to 5 days depending of chosen method.</p>
      </div>
      <div class="loop" *ngFor="let item of pendingList?.results; let i = index" [@disappear]="item.state || 'in'">
        <div class="entry" (click)="checkConfirmCancel(item)" fxLayout="row" fxLayoutAlign="center center">
          <div class="item" fxLayoutAlign="start center">
            <div class="name-group">
              <div class="type">
                <div class="data" i18n>
                  {{item.date | slice:0:10}}
                </div>
                <div class="h16-22" i18n>
                  {{item.operator.name}}
                </div>
              </div>
            </div>
          </div>
          <div class="item-tail" fxLayoutAlign="start center">
            <div class="tail-group">
              <div class="amount h16-22" i18n>
                {{item.transaction_value}} {{item.transaction_currency}}
              </div>
              <div class="h12" *ngIf="isDesktop">
                <button round-button size="small" filled="filled" color="warn" i18n>cancel</button>
              </div>
            </div>
          </div>
        </div>
        <div class="h12" *ngIf="!isDesktop">
          <button round-button size="small" filled="filled" color="warn" i18n (click)="checkConfirmCancel(item)">cancel</button>
        </div>
      </div>
    </div>
    <div class="pagination">
      <div class="buttons">
        <div (click)="previousPage()" [class.button-icon-only-back]="hasPreviousPage" [class.button-icon-only-back-disabled]="!hasPreviousPage">
          <div class="chevron-left">
            <img class="icon" src="assets/desktop/yellow-arrow.svg" alt="yellow-arrow">
          </div>
        </div>
        <div class="range-text">{{(currentPage-1)*10+1}} - {{currentPage*10 < itemsCount ? currentPage*10 : itemsCount }} / {{itemsCount}}</div>
        <div (click)="nextPage()" [class.button-icon-only-forward]="hasNextPage" [class.button-icon-only-forward-disabled]="!hasNextPage">
          <div class="chevron-right">
            <img class="icon" src="assets/desktop/yellow-arrow.svg" alt="yellow-arrow">
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #nothing>
    <div class="no-list">
      <div class="no-withdrawal-pendings" [ngClass]="detectDesktopService.isDesktop() ? 'desktop' : 'mobile'">
        <app-mobile-page-back-header iconName="icon-pending-withdrawals.svg" [position]="detectDesktopService.isDesktop() ? 'center' : 'left'">Pending Withdrawals</app-mobile-page-back-header>
        <img src="{{licenceService.getKey('pending-withdrawal')}}" alt="">
        <h1 i18n>There are no pending withdrawals at the moment...</h1>
      </div>
    </div>
  </ng-template>
</div>
