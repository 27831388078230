import { Component, OnInit } from '@angular/core';
import { PendingWithdrawalService } from 'src/app/services/payment/withdrawal/pending-withdrawal.service';
import { BasePageComponentWithDialogs } from '../../base-page/base-page.component';
import { MatDialog } from '@angular/material/dialog';
import { take, takeUntil } from 'rxjs/operators';
import { PendingWithdrawalList } from 'src/app/services/payment/withdrawal/withdrawal.models';
import { CancelWithdrawalService } from 'src/app/services/payment/withdrawal/cancel-withdrawal.service';
import { UntypedFormBuilder } from '@angular/forms';
import { ToggleMenuBackService } from 'src/app/services/utils/toggle-menu-back.service';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { LicenceService } from 'src/app/services/utils/licence.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { PaymentIqService } from 'src/app/services/payment/methods/paymentiq.service';

const confirmationMsg: string = $localize`:@@pendingWithdrawalConfirmationMsg:Are you sure you want to cancel the withdrawal?`;

@Component({
  selector: 'app-pending-withdrawal-list',
  templateUrl: './pending-withdrawal-list.component.html',
  styleUrls: ['./pending-withdrawal-list.component.scss'],
  animations: [
    trigger('disappear', [
      state('in', style({ opacity: 1, height: '*', transform: 'scale(1)' })),
      state('out', style({ opacity: 0, height: 0, transform: 'scale(0)' })),
      transition('in => out', [
        animate('0.5s ease-in')
      ])
    ])
  ]
})
export class PendingWithdrawalListComponent extends BasePageComponentWithDialogs implements OnInit {
userMessagesResp: any;
hasNextPage: boolean = false;
hasPreviousPage: boolean = false;
itemsCount: number;
successMsg: string;

  constructor(
    private pendingListService: PendingWithdrawalService,
    private cancelWithdrawalService: CancelWithdrawalService,
    private paymentIqService: PaymentIqService,
    dialog: MatDialog,
    protected licenceService: LicenceService,
    private formBuilder: UntypedFormBuilder,
    private toggleBackService: ToggleMenuBackService,
    protected detectDesktopService: DetectDeviceService,
  ) {
    super(dialog, detectDesktopService);
  }
  pendingList: PendingWithdrawalList;

  cancelWithdrawalForm = this.formBuilder.group({
    payment_id: ['', []],
  });

  ngOnInit(): void {
    this.generateList();
    this.toggleBackService.showBack();
    this.isDesktop = this.detectDesktopService.isDesktop();
    this.successMsg = this.isDesktop
    ? $localize`:@@pendingWithdrawalSuccessMsg:Your withdrawal request has been successfully cancelled. Your funds are now available in your wallet.`
    : $localize`:@@walletFundsAvailableMsg:Your funds are available in your Lemon wallet.`;
  }

  generateList() {
    this.pendingListService.getList().pipe(take(1)).subscribe({
      next: (resp) => {
        this.pendingList = resp;
        this.itemsCount = resp.count;
        this.hasNextPage = resp.next !== null;
        this.hasPreviousPage = resp.previous !== null;
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  removeItem(index: number) {
    this.pendingList.results.splice(index, 1);
    this.generateList();
  }

  checkConfirmCancels(item) {
    item.state = 'out';
    const index = this.pendingList.results.findIndex((i: any) => i.id === item.id);
    if (index !== -1) {
      setTimeout(() => this.removeItem(index), 700);
    }
  }

  checkConfirmCancel(item) {
    this.openConfirmDialog(confirmationMsg, item, this.confirm.bind(this));
  }

  confirm(data: any) {
    if (data.cancel_method === "paymentiq") {
      this.paymentIqService.cancelPandingWithdrawal(data.ext_transaction_id).pipe(take(1)).subscribe((resp) => {
        if (resp.success) {
          const successImg = this.licenceService.getKey('pending-withdrawal');
          this.openSuccessDialog(this.successMsg, "Success!", () => this.callList(data), null, successImg);
        }
        else {
          this.openErrorDialog()
        }
      })
    }
    else {
      this.cancelWithdrawalForm.get('payment_id').setValue(data.id);
      this.cancelWithdrawalService.cancelWithdrawal(this.cancelWithdrawalForm).pipe(takeUntil(this.unsubscribe)).subscribe(
        resp => {
          const successImg = this.licenceService.getKey('pending-withdrawal');
          this.openSuccessDialog(this.successMsg, "Success!", () => this.callList(data), null, successImg);
          // this.checkConfirmCancels(data);
        },
        error => {
          this.openErrorDialog(error.error?.detail);
          this.generateList();
        }
      );
    }

  }

  callList(data) {
    this.checkConfirmCancels(data);
  }

  nextPage() {
    if (this.hasNextPage) {
      this.pendingListService.goToNextPage().subscribe({
        next: (resp) => {
          this.pendingList = resp;
          this.itemsCount = resp.count;
          this.hasNextPage = resp.next !== null;
          this.hasPreviousPage = resp.previous !== null;
        },
        error: (error) => {
          console.log(error);
        }
      })
      this.scrollToTop();
    }
  }

  previousPage() {
    if (this.hasPreviousPage) {
      this.pendingListService.goToPreviousPage().subscribe({
        next: (resp) => {
          this.pendingList = resp;
          this.itemsCount = resp.count;
          this.hasNextPage = resp.next !== null;
          this.hasPreviousPage = resp.previous !== null;
        },
        error: (error) => {
          console.log(error);
        }
      })
      this.scrollToTop();
    }
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
