<!-- <div class="dialog-wrapper">
    <div class="dialog-container" fxLayoutAlign="space-between center" fxLayout="column">
        <h2 *ngIf="successData.title">{{successData.title}}</h2>
        <div>
            <p>
                {{successData.msg}}
            </p>
        </div>
        <div *ngIf="balanceInfo?.bonus_balance>0" fxLayoutAlign="space-between center">
            <div class="active-bonus-info"><span i18n>You have an active bonus that may affect your balance. Check</span>&nbsp;<span class="bonus-link" (click)="closeDialog()" [routerLink]="['client-area','bonus-queue']" i18n>My Bonuses</span></div>
        </div>
        <div class="footer" fxLayout="row" fxLayoutAlign="center center">
            <div class="buttons" fxLayoutAlign="center center">
                <button mat-flat-button color="primary" (click)="closeDialog()" type='button'>OK</button>
            </div>
        </div>
    </div>
    <app-close-dialog-button (closeEvent)="closeDialog()"></app-close-dialog-button>
</div> -->

<div class="dialog-wrapper">
    <div class="dialog-header">
        <ng-container *ngIf="successData.title">
            <h2>{{successData.title}}</h2>
        </ng-container>

        <app-close-dialog-button (closeEvent)="closeDialog()"></app-close-dialog-button>
    </div>

    <mat-divider></mat-divider>

    <div class="dialog-body">
        <div class="image-box">
            <img src="assets/images/wallet_full 3.png">
        </div>

        <ng-container *ngIf="successData.msg">
            <p style="text-align: left;">{{successData.msg}}</p>
        </ng-container>
    </div>

    <div class="dialog-footer">
        <div class="buttons">
            <button round-button color="primary" (click)="closeDialog()" type='button'>OK</button>
        </div>
    </div>
</div>